import React from 'react'
import LayoutFAQ from "../../components/layout/layoutFAQ"
import FaqSidebar from "../../components/FAQs/faqSidebar"
import {Sections} from "../../components/FAQs/faqData"
const GettingPaid = () => {
  return <>
    <FaqSidebar selected={Sections.getting_paid}/>
  </>
}

GettingPaid.Layout = LayoutFAQ

export default GettingPaid
